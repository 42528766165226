import React from 'react';
import ReactDOM from 'react-dom';
import { DivOverallWrap } from '../../../../react/components/utility/wrap';
import { LoginForm } from '../../../../react/pages/sessions/new/loginForm';

document.addEventListener('DOMContentLoaded', () => {
  const domToBinding = document.getElementById('react-sessions-new-root')!;
  if(domToBinding == null) return

  ReactDOM.render(
    <>
      <DivOverallWrap>
        <LoginForm />
      </DivOverallWrap>
    </>,
    domToBinding);
});
