import { string } from "prop-types";
import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { PageTitle } from "../../../components/atoms/pageTitle";
import  { BORDER, ORANGE_PRIMARY, TEXT_PRIMARY } from '../../../config/Constants';
import { axios } from '../../../config/Axios';
import { ErrorMessages } from "../../../components/atoms/errorMessages";
import { ALink } from "../../../components/utility/link";
import { DivFlexCenCen } from "../../../components/utility/flex";

export const LoginForm:FC = () => {
  const [loginFailedMessages, setLoginFailedMessages] = useState<string[]>([])

  const loginSubmit:React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    axios.post('/api/login', {
      password: (e.target as any).password.value,
      email: (e.target as any).email.value,
    })
      .then(response => {
        if(response.data.isActivated && response.data.isValid) {
          window.location.href = response.data.redirectPath;
        } else {
          setLoginFailedMessages(response.data.invalidMessages);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return(
    <>
      <PageTitle text={'ログイン'}/>
      <DivSignUpWrap>
        <FormSignUp onSubmit={loginSubmit}>
          <DivFormLabelSet>
            <LabelForTextField>メールアドレス</LabelForTextField>
            <InputTextField
              type={'email'}
              name={'email'}
            />
          </DivFormLabelSet>
          <DivFormLabelSet>
            <LabelForTextField>パスワード</LabelForTextField>
            <InputTextField
              type={'password'}
              name={'password'}
            />
          </DivFormLabelSet>
          <InputButtonUserRegister
            type={'submit'}
            value={'ログイン'}
          />
        </FormSignUp>
        <ErrorMessages errorMessages={loginFailedMessages}/>
        <DivFlexCenCen style={{marginTop: '2rem'}}>
          <ALink href={'/users/new'}>会員登録はこちら</ALink>
        </DivFlexCenCen>
        <DivFlexCenCen style={{marginTop: '1.5rem'}}>
          <ALink href={'/reset_passwords'}>パスワードを忘れた方はこちら</ALink>
        </DivFlexCenCen>
      </DivSignUpWrap>
    </>
  )
}

const DivSignUpWrap = styled.div`
  padding: 2rem 1rem;
`

const DivFormLabelSet = styled.div`
  margin: 1.5rem 0 0 0;
`

const FormSignUp = styled.form`
`

const LabelForTextField = styled.label`
  font-size: 1.125rem;
  display: block;
  color: ${TEXT_PRIMARY}
`

const InputTextField = styled.input`
  box-sizing: border-box;
  height: 3rem;
  padding: 0.125rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 1.25rem;
  width: 100%;
  border: 1px solid ${BORDER};
  &:focus {
    border: 1px solid ${ORANGE_PRIMARY};
    outline: 0;
  }
`

const InputButtonUserRegister = styled.input`
  height: 2.5rem;
  font-size: 1.125rem;
  margin-top: 2rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  white-space: nowrap;
  opacity: 1;
  transition: all 0.2s ease;
  text-decoration: none;
  appearance: none;
  width: 100%;
  background-color: ${ORANGE_PRIMARY};
  color: #fff;
  border: 1px solid ${ORANGE_PRIMARY};
  &:disabled{
    opacity: 0.3;
  }
`;
